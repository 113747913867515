import { GET_HOST_LIVE_HISTORY, GET_HOST_LIVE_ROOM_HISTORY } from "./types";

const initialState = {
  liveRoomHistory: [],
  liveHistory: [],
  audioValidDays:null,
  videoValidDays:null
};

const liveRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST_LIVE_ROOM_HISTORY:
      return {
        ...state,
        liveRoomHistory: action.payload.history,
      };

    case GET_HOST_LIVE_HISTORY:
      return {
        ...state,
        liveHistory: action.payload?.data,
        audioValidDays:action.payload?.audioValidDays,
        videoValidDays:action.payload?.videoValidDays
      };

    default:
      return state;
  }
};

export default liveRoomReducer;
