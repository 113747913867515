import axios from "axios";
import { Toast } from "../../util/Toast";
import { GET_HOST_LIVE_HISTORY, GET_HOST_LIVE_ROOM_HISTORY } from "./types";

export const getLiveHistory = (hostId, month) => (dispatch) => {
  axios
    .get(`hostLiveHistory/host/liveHistory?hostId=${hostId}&month=${month}`)
    .then((res) => {
      dispatch({
        type: GET_HOST_LIVE_HISTORY,
        payload: res.data ,
      });
    })
    .catch((error) => console.log("error", error));
};
